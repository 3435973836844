@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Poppins:wght@400;600;700&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Inter', sans-serif;
  color: #D1D5DB;
  background: #111;
  scroll-behavior: smooth;
}

.PortfolioGrid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 80%;
}

.PortfolioGrid:has(div:only-child) {
  grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
  width: 50%;
}

